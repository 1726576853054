import React, { Component } from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import './carousel.css'

import bosch from "../images/logos/bosch.png"
import uniliver from "../images/logos/uniliver.png"
import mha from "../images/logos/mha.png"

export default class MobileSlider extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      centerMode: true
    };
    return (
      <div style={{paddingTop: "2%"}}>
        <p style={{fontWeight: "700", textAlign: "center"}}>Trusted and loved by world leading organizations</p>
        <Slider {...settings} className="slider-class" style={{height: "300px", marginBottom: "5%", paddingRight: "5%", paddingLeft: "-2%"}}>
          <div>
          <img
              src={bosch}
              style={{ width: "50%", marginLeft: "25%", marginBottom: "10px" }}
              alt="PaySafe"
            />
            <p className="slide-p">"Results from eQuoo showed statistically significant increases in well-being metrics and a significant
              decrease in anxiety when using the app over a time frame of five weeks"
            </p>
            <p className="review-name">Bosch</p>
          </div>
          <div >
          <img
              src={uniliver}
              style={{ width: "50%", marginLeft: "25%", marginBottom: "10px" }}
              alt="PaySafe"
            />
          <p className="slide-p">“CLEAR is committed to enabling people to perform at their best, and this is why we have partnered with eQuoo 
            to give young people the tools to build resilience, so they can better cope with mental health challenges.”
            </p>
            <p className="review-name" style={{fontSize: "12px"}}>Tran Tue Tri
            <br />
            Global Vice President, Unilever
            </p>
          </div>
          <div >
          <img
              src={mha}
              style={{ width: "50%", marginLeft: "25%", marginBottom: "10px" }}
              alt="PaySafe"
            />
          <p className="slide-p">"When we offered different types of services to online users, more people used eQuoo than any other mental health support.  
          Offering a game based mental health support fills a much-needed gap."
            </p>
            <p className="review-name" style={{fontSize: "12px"}}>Theresa Nguyen, LCSW,  
            <br />
            Chief Program Officer, Mental Health America
            </p>
          </div>

        </Slider>
      </div>
    );
  }
}