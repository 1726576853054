import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import "bootstrap/dist/css/bootstrap.min.css"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Col, Row, Button, Carousel } from "react-bootstrap"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faStar } from "@fortawesome/free-solid-svg-icons"

import slideBG from "../images/slider_large.jpg"
import handHeldPhone from "../images/hand_held_phone.jpg"
import handHeldPhoneWoman from "../images/handheld_woman.jpg"

import handHeldWomanRound from "../images/handheld_woman_round.fw.png"

import SimpleSlider from "../components/carousel"
import MobileSlider from "../components/carousel-mobile"

import "../styles/index.css"

import BlogList from "./blog-list"

//TESTIMONIALS
import bosch from "../images/logos/gray/bosch.png"
import rrc from "../images/logos/gray/rrc.png"
import uniliver from "../images/logos/gray/uniliver.png"
import sense from "../images/logos/gray/sense.png"
import mha from "../images/logos/gray/mha.png"
import clear from "../images/logos/gray/clear.png"

const IndexPage = () => (
  <Layout>
    <Seo title="Boost your EQ and Supercharge your Personal Development" />
    <div id="landing-section">
    <section
      id="landing"
      className="section-container"
      style={{ marginTop: "4%" }}
    >
      <Row>
        <Col
          lg={{ span: 5, offset: 1 }} md={{ span: 10, offset: 1 }} xs={{ span: 10, offset: 1 }}
          className="justify-content-center align-self-center"
        >
          <h1 style={{ fontSize: "54px" }}>eQuoo for business</h1>
          <p>
            eQuoo is the only clinically validated platform that uses gaming,
            psychology, and AI to harness brain's reward system to keep people
            motivated to learn the critical skills to improve their mental and
            emotional health.
          </p>
          <br />
          <Link to="contact-us">
            <Button className="gradient-button">BOOK A DEMO</Button>
          </Link>
          
        </Col>
        <Col lg="1"></Col>
        <Col lg="4" className="d-none d-lg-block">
          <StaticImage
            src="../images/landing_sprite_equoo.png"
            width={500}
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt="Screenshots"
            style={{ marginBottom: `1.45rem` }}
          />
        </Col>
      </Row>
    </section>
    <section className="section-container">
      <Row>
        <Col lg="12" style={{paddingTop: "2%"}} className="section-equoo-effect">
          <p style={{ textAlign: "center", fontWeight: "700"}}>
            The eQuoo Effect
          </p>
        </Col>
      </Row>
      <Row>
        <Col
          xl={{ span: 8, offset: 2 }}
          lg={{ span: 10, offset: 1 }}
          md={{ span: 10, offset: 1 }}
          xs={{ span: 8, offset: 2 }}
          className="justify-content-center align-self-center"
          style={{paddingBottom: "3%"}}
        >
          <Row>
            <Col
              lg="3"
              md="3"
              className="equoo-bg"
              style={{ marginRight: "12.5%" }}
            >
              <h2
                style={{
                  textAlign: "center",
                  fontWeight: "700",
                  fontSize: "64px",
                  color: "white",
                  paddingTop: "%",
                }}
              >
                65%
              </h2>
              <p
                style={{
                  color: "white",
                  textAlign: "center",
                  paddingLeft: "1%",
                  paddingRight: "1%",
                }}
              >
                Monthly active users who spend 15 minutes per sessions (5x
                longer than any other mental health app)
              </p>
            </Col>
            <Col
              lg="3"
              md="3"
              className="equoo-bg"
              style={{ marginRight: "12.5%" }}
            >
              <h2
                style={{
                  textAlign: "center",
                  fontWeight: "700",
                  fontSize: "64px",
                  color: "white",
                  paddingTop: "%",
                }}
              >
                3x
              </h2>
              <p
                style={{
                  color: "white",
                  textAlign: "center",
                  paddingLeft: "1%",
                  paddingRight: "1%",
                }}
              >
                Higher retention rate than any other mental health app
              </p>
            </Col>
            <Col lg="3" md="3" className="equoo-bg">
              <h2
                style={{
                  textAlign: "center",
                  fontWeight: "700",
                  fontSize: "64px",
                  color: "white",
                  paddingTop: "%",
                }}
              >
                82%
              </h2>
              <p
                style={{
                  color: "white",
                  textAlign: "center",
                  paddingLeft: "1%",
                  paddingRight: "1%",
                }}
              >
                Implement the skills they learn from eQuoo immediately
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    </section>
    </div>
    <section >
    <Row style={{paddingTop: "5%", backgroundColor: "#F4EDF4"}}>
      <Col
              lg={{ span: 3, offset: 2 }}
              md={{ span: 3, offset: 2 }}
              xs={{ span: 8, offset: 2 }}
              className="equoo-bg"
              style={{maxHeight: "200px", maxWidth: "200px"}}
            >
              <h2
                style={{
                  textAlign: "center",
                  fontWeight: "700",
                  fontSize: "64px",
                  color: "white",
                  paddingTop: "%",
                }}
              >
                1 in 4
              </h2>
              <p
                style={{
                  color: "white",
                  textAlign: "center",
                  paddingLeft: "1%",
                  paddingRight: "1%",
                  fontSize: "16px"
                }}
              >
                Will have a mental health issue, 4 in 4 will struggle with emotional health
              </p>
            </Col>
            <Col lg="2"  className="justify-content-center align-self-center d-none d-lg-block" >
          <StaticImage
            src="../images/joy_standing_2.png"
            width={500}
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt="Joy standing"
            style={{ marginBottom: `1.45rem` }}
          />
        </Col>
            <Col
          lg={{ span: 4, offset: 1 }}
          md={{ span: 6, offset: 1 }}
          xs={{ span: 10, offset: 1 }}
          className=""
        >
            <Row>
            <p style={{fontWeight: "700" }}>How eQuoo works for your people</p>
            <p>
              eQuoo helps your people gain a better understanding of emotions and 
              effective communication through a gamified digital experience. The tips and 
              tricks they learn while they navigate eQuoo, will build resilience, boost relationship 
              skills and advance personal development, while lowering anxiety and depression.
            </p>
            </Row>
            <Row>
            <img
            src={handHeldWomanRound}
            alt="Hand held phone"
            style={{ width: "70%", height: "auto", marginBottom: "0px" }}
          />
            </Row>
          </Col>
          <Col lg={{ span: 8, offset: 2 }} xs={{ span: 10, offset: 1 }}>
          <Row>
              <Col lg="12">
                  <p style={{fontSize: "12px"}}>Source: eQuoo clinical trial metrics - ARM (Adult Resilience Measure), 
                      <br />
                      PGIS (Personal Growth Initiative Scale), RPRS (Ryff's Scales of Psycological Wellbeing).
                  </p>
              </Col>
          </Row>
      </Col>   
      </Row>
    </section>
    <section className="section-container" style={{ marginTop: "2%" }}>
      <Row>
        <Col lg="12">
          <p style={{ textAlign: "center", fontWeight: "700" }}>
            What we achieve
          </p>
        </Col>
      </Row>
      <Row>
        <Col
          lg={{ span: 5, offset: 2 }}
          xs={{ span: 10, offset: 1 }}
          className="justify-content-center align-self-center"
        >
          <Row>
            <p style={{ fontWeight: "700" }}>Engaging experience</p>
            <p>
              The features in the game increase users' drive to achieve their
              goals with experience divided into physical, mental, emotional and
              social dimentions. Plus, players love eQuoo - it has a top rating
              on the app stores.
            </p>
          </Row>
          <Row >
            <Col lg="4" md="4" className="d-none d-md-block">
              <StaticImage
                src="../images/screenshots/1-min.png"
                width={500}
                quality={95}
                formats={["auto", "webp", "avif"]}
                alt="eQuoo screenshot"
                style={{ marginBottom: `1.45rem` }}
              />
            </Col>
            <Col lg="4" md="4" className="d-none d-md-block">
              <StaticImage
                src="../images/screenshots/4-min.png"
                width={500}
                quality={95}
                formats={["auto", "webp", "avif"]}
                alt="eQuoo screenshot"
                style={{ marginBottom: `1.45rem` }}
              />
            </Col>
            <Col lg="4" md="4" className="d-none d-md-block">
              <StaticImage
                src="../images/screenshots/6-min.png"
                width={500}
                quality={95}
                formats={["auto", "webp", "avif"]}
                alt="eQuoo screenshot"
                style={{ marginBottom: `1.45rem` }}
              />
            </Col>
          </Row>
        </Col>
        <Col lg="2" className="justify-content-center align-self-center d-none d-lg-block" >
          <StaticImage
            src="../images/joy_standing.png"
            width={500}
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt="Joy standing"
            style={{ marginBottom: `1.45rem` }}
          />
        </Col>
        <Col
              lg={{ span: 3, offset: 0 }}
              md={{ span: 3, offset: 5 }}
              xs={{ span: 6, offset: 3 }}
              className="equoo-bg equoo-bg-md"
              style={{maxHeight: "200px", maxWidth: "200px", marginTop: "20%"}}
            >
              <h2
                style={{
                  textAlign: "center",
                  fontWeight: "700",
                  fontSize: "64px",
                  color: "white",
                  paddingTop: "%",
                }}
              >
                7x
              </h2>
              <p
                style={{
                  color: "white",
                  textAlign: "center",
                  paddingLeft: "1%",
                  paddingRight: "1%",
                }}
              >
                Higher Rol for preventative solutions than EAP
              </p>
            </Col>
        <Col
          lg={{ span: 1, offset: 0 }}
          
          className="justify-content-center align-self-center"
        ></Col>
      </Row>
      <Row style={{marginTop: "2%"}}>
      <Col
              lg={{ span: 3, offset: 2 }}
              xs={{ span: 6, offset: 3 }}
              md={{ span: 3, offset: 1 }}
              className="equoo-bg"
              style={{maxHeight: "200px", maxWidth: "200px"}}
            >
              <h2
                style={{
                  textAlign: "center",
                  fontWeight: "700",
                  fontSize: "64px",
                  color: "white",
                  paddingTop: "%",
                }}
              >
                2%
              </h2>
              <p
                style={{
                  color: "white",
                  textAlign: "center",
                  paddingLeft: "1%",
                  paddingRight: "1%",
                  fontSize: "16px"
                }}
              >
                Of wellbeing apps are clinically proven - eQuoo is proud to be in that 2%
              </p>
            </Col>
            <Col lg="1" md="1">
            </Col>
            <Col
          lg={{ span: 5, offset: 1 }}
          md={{ span: 6, offset: 0 }}
          xs={{ span: 10, offset: 1 }}
          className=""
        >
            <p style={{fontWeight: "700" }}>Proven to work</p>
            <p>
              eQuoo is in the 2% of mental health apps that have undergone clinical trials.
              The app is medically proven and evidence-based platform and is accredited by the NHS and ORCHA.
              Take a look at our clinical research here.
            </p>
          </Col>
            
      </Row>
      </section>
      <Row id="proven-to-work">

      </Row>
      <section className="section-container">
      <Row style={{marginTop: "2%"}}>
          <Col lg={{ span: 12, offset: 0 }} className="d-flex justify-content-center align-self-center">
          <StaticImage
            src="../images/stats/1.jpg"
            width={900}
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt="Joy standing"
            style={{ marginBottom: `1.45rem` }}
          />
          </Col>
      </Row>
      <Row style={{marginTop: "2%"}}>
      <Col
          lg={{ span: 5, offset: 2 }}
          md={{ span: 6, offset: 1 }}
          xs={{ span: 10, offset: 1 }}
          className=""
        >
            <p style={{fontWeight: "700" }}>Robust analytics and insights</p>
            <p>
              Access detailed reporting on employee engagement and retention, the clinical impact on mental 
              health, at risk populations, trends and employee-reported value - 
              including the impact on productivity and enjoyment in playing the game.
            </p>
          </Col>
          <Col lg="1" md="1">
            </Col>
      <Col
              lg={{ span: 2, offset: 1 }}
              md={{ span: 3, offset: 0 }}
              xs={{ span: 8, offset: 2 }}
              className="equoo-bg"
              style={{maxHeight: "200px", maxWidth: "200px"}}
            >
              <h2
                style={{
                  textAlign: "center",
                  fontWeight: "700",
                  fontSize: "64px",
                  color: "white",
                  paddingTop: "%",
                }}
              >
                48%
              </h2>
              <p
                style={{
                  color: "white",
                  textAlign: "center",
                  paddingLeft: "1%",
                  paddingRight: "1%",
                  fontSize: "16px"
                }}
              >
                Gen Z say they feel stressed most of the time (BetterWorkMH)
              </p>
            </Col>
            
      </Row>
      <Row style={{marginTop: "2%", marginLeft: "1%", marginRight: "1%"}}>
          <Col lg={{ span: 12, offset: 0 }} className="d-flex justify-content-center align-self-center">
          <StaticImage
            src="../images/stats/2.png"
            width={800}
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt="Joy standing"
            style={{ marginBottom: `1.45rem` }}
          />
          </Col>
      </Row>
    </section>
    <section>
      <Row style={{ backgroundColor: "#E4F5FC" }}>
        <Col lg={{ span: 8, offset: 2 }} className="d-none d-md-block">
          <div>
            <SimpleSlider></SimpleSlider>
          </div>
        </Col>
        <Col lg={{ span: 8, offset: 2 }} className="d-block d-sm-none">
          <div>
            <MobileSlider></MobileSlider>
          </div>
        </Col>
      </Row>
      <Row style={{ paddingTop: "1%", backgroundColor: "#E4F5FC" }}>
        <Col lg={{ span: 8, offset: 2 }}>
          <div className="badges">
            <img
              src={bosch}
              style={{ width: "16.5%", paddingRight: "1%", paddingLeft: "1%" }}
              alt="Bosch"
            />
            <img
              src={rrc}
              style={{ width: "16.5%", paddingRight: "1%" }}
              alt="Resilience Research Centre"
            />
            <img
              src={uniliver}
              style={{ width: "16.5%", paddingRight: "1%" }}
              alt="Uniliver"
            />
            <img
              src={sense}
              style={{ width: "16.5%", paddingRight: "1%" }}
              alt="Sense"
            />
            <img
              src={mha}
              style={{ width: "16.5%", paddingRight: "1%" }}
              alt="Mental Health America"
            />
            <img
              src={clear}
              style={{ width: "16.5%", paddingRight: "1%" }}
              alt="Clear"
            />
          </div>
        </Col>
      </Row>
    </section>
    <section>
      <Row style={{ marginTop: "auto", marginBottom: "0px", paddingTop: "0%", paddingLeft: "0px" }}>
        <Col lg="12" className="d-none d-lg-block">
          <img
            src={handHeldPhoneWoman}
            alt="Hand held phone"
            style={{ width: "100%", height: "auto", marginBottom: "0px" }}
          />
          <div></div>
        </Col>
      </Row>
    </section>
    <section >
      <Row style={{paddingTop: "5%", paddingBottom: "5%", paddingLeft: "1%", paddingRight: "1%", backgroundColor: "#F4EDF4"}}>
        <Col lg={{ span: 12, offset: 0 }} className="d-flex justify-content-center align-self-center">
        <StaticImage
            src="../images/gem_wheel.png"
            width={800}
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt="Joy standing"
            style={{ marginBottom: `1.45rem` }}
          />
        </Col>
      </Row>
    </section>
    <section >
    <Row id="phones-row" style={{paddingTop: "5%", paddingBottom: "0px"}}>
      <Col>
      <Row>
      <Col lg={{ span: 2, offset: 2 }} className="d-none d-lg-block">
        <StaticImage
            src="../images/tilted_ss/2-min.png"
            width={800}
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt="Joy standing"
            style={{ marginBottom: `1.45rem` }}
          />
        </Col>
        <Col lg={{ span:4, offset: 0 }} xs={{ span: 10, offset: 1 }}>
        <p style={{fontWeight: "700", textAlign: "center", marginBottom: "3px" }}>Build resilience</p>
            <p style={{textAlign: "center" }}>
              People with higher levels of resilience feel and perform 
              better. They are 20% less likely to suffer from depression, be 
              absent from work or have dibs in productivity.
            </p>
            <p style={{fontWeight: "700", textAlign: "center", marginBottom: "3px" }}>Boost relationship skills</p>
            <p style={{textAlign: "center" }}>
              Percieved negative interpersonal relationships in the organisation contribute
              to up to 25% of annual absenteeism. Positive relationships in an organisation have a significant 
              impact on job satisfaction, skill development and use, staff turnover, 
              workplace morale, absenteeism and quality of life.
            </p>
        </Col>
        <Col lg={{ span:2 }} className="d-none d-lg-block">
        <StaticImage
            src="../images/tilted_ss/3-min.png"
            width={800}
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt="Joy standing"
            style={{ marginBottom: `1.45rem` }}
          />
      </Col>
      </Row>
      <Row >
        <Col lg={{ span: 2, offset: 2 }} className="d-none d-lg-block">
        <StaticImage
            src="../images/tilted_ss/4-min.png"
            width={800}
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt="Joy standing"
            style={{ marginBottom: `1.45rem` }}
          />
        </Col>

        <Col xs={{ span: 8, offset: 2 }} className="d-block d-sm-none">
        <StaticImage
            src="../images/screenshots/1-min.png"
            width={800}
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt="Joy standing"
            style={{ marginBottom: `1.45rem` }}
          />
        </Col>
        <Col lg={{ span:4 , offset: 0}} xs={{ span: 10, offset: 1 }}>
        <p style={{fontWeight: "700", textAlign: "center", marginBottom: "3px" }}>Advanced personal growth</p>
            <p style={{textAlign: "center" }}>
              80% of young adults between the ages of 18 and 34 believe that an emphasis on 
              personal growth is the most important feature of a company's culture. Today, 69% of young
              professionals aspire to be leaders in the next five years. 
            </p>
            <p style={{fontWeight: "700", textAlign: "center", marginBottom: "3px" }}>Lower anxiety and depression</p>
            <p style={{textAlign: "center" }}>
              Depression is alongside anxiety as the world's most prevalent mental illness. 
              Symptoms of depression, such as lack of drive, motivation, energy and the sense of emptiness 
              make depression a real threat to any organisation that relies on the performance and engagement of
              people's talent. Anxiety is accompanied by symptoms like the inability to focus, irritability and focus 
              on the experience of fear - all which have a hue negative effect on productivity and job 
              satisfaction.
            </p>
        </Col>
        <Col lg={{ span:2 }} className="d-none d-lg-block">
        <StaticImage
            src="../images/tilted_ss/1-min.png"
            width={800}
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt="Joy standing"
            style={{ marginBottom: `1.45rem` }}
          />
      </Col>
      </Row>
      </Col>
        
      </Row>
      
    </section>
    <section id="reviews-section">
      <Row
        className="my-auto"
        style={{
          marginTop: "auto",
          marginBottom: "auto",
          minHeight: "700px",
          paddingTop: "8%",
        }}
      >
        <Col
          xl={{ span: 6, offset: 3 }}
          lg={{ span: 6, offset: 3 }}
          className="justify-content-center align-self-center"
        >
          <p
            style={{
              color: "white",
              textAlign: "center",
              paddingLeft: "1%",
              paddingRight: "1%",
              marginBottom: "0px",
              marginTop: "10px",
            }}
          >
            Trusted and loved by thousands of fans
          </p>
          <div>
            <Carousel style={{ zIndex: "4" }}>
              <Carousel.Item
                interval={3000}
                style={{
                  height: "100%",
                  minHeight: "200px",
                }}
                className="slider-item"
              >
                <img
                  className="d-block w-100 slider-bg-img"
                  src={slideBG}
                  alt="First slide"
                  style={{ minHeight: "300px" }}
                />
                <Carousel.Caption>
                  <div
                    style={{
                      paddingTop: "0px",
                    }}
                  >
                    <span
                      style={{
                        marginLeft: "0px",
                        float: "left",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faStar}
                        size="1x"
                        style={{
                          color: "#F7C912",
                          stroke: "#D4D4D4",
                          strokeWidth: "15",
                        }}
                      />
                      <FontAwesomeIcon
                        icon={faStar}
                        size="1x"
                        style={{
                          color: "#F7C912",
                          stroke: "#D4D4D4",
                          strokeWidth: "15",
                        }}
                      />
                      <FontAwesomeIcon
                        icon={faStar}
                        size="1x"
                        style={{
                          color: "#F7C912",
                          stroke: "#D4D4D4",
                          strokeWidth: "15",
                        }}
                      />
                      <FontAwesomeIcon
                        icon={faStar}
                        size="1x"
                        style={{
                          color: "#F7C912",
                          stroke: "#D4D4D4",
                          strokeWidth: "15",
                        }}
                      />
                      <FontAwesomeIcon
                        icon={faStar}
                        size="1x"
                        style={{
                          color: "#F7C912",
                          stroke: "#D4D4D4",
                          strokeWidth: "15",
                        }}
                      />
                    </span>
                    <br />
                    <p
                      style={{
                        color: "#737373",
                        textAlign: "left",
                      }}
                    >
                      EmilyS089, 08.13.2018
                    </p>
                    <p
                      style={{
                        color: "#3D3D3D",
                        marginBottom: "2px",
                        textAlign: "left",
                      }}
                    >
                      Changing my life
                    </p>
                    <p
                      style={{
                        color: "#3D3D3D",
                        textAlign: "left",
                      }}
                      className="reviews-p"
                    >
                      I downloaded this app only a couple of days ago after I
                      heard friends speaking about it. It already changed my
                      behavior.. I’m way more conscious. I first think about the
                      things I say and the potential consequences. I try to put
                      myself into my partners shoes. For me, this app helps
                      learning more about empathy and, as a result, it makes me
                      understand people behaviors better. I feel better and more
                      confident and most important: the app is fun. Love the
                      design
                    </p>
                  </div>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item
                interval={3000}
                style={{
                  width: "100%",
                  minHeight: "200px",
                }}
              >
                <img
                  className="d-block w-100 slider-bg-img"
                  src={slideBG}
                  style={{ minHeight: "300px" }}
                  alt="First slide"
                />
                <Carousel.Caption>
                  <div
                    style={{
                      paddingTop: "0px",
                    }}
                  >
                    <span
                      style={{
                        marginLeft: "0px",
                        float: "left",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faStar}
                        size="1x"
                        style={{
                          color: "#F7C912",
                          stroke: "#D4D4D4",
                          strokeWidth: "15",
                        }}
                      />
                      <FontAwesomeIcon
                        icon={faStar}
                        size="1x"
                        style={{
                          color: "#F7C912",
                          stroke: "#D4D4D4",
                          strokeWidth: "15",
                        }}
                      />
                      <FontAwesomeIcon
                        icon={faStar}
                        size="1x"
                        style={{
                          color: "#F7C912",
                          stroke: "#D4D4D4",
                          strokeWidth: "15",
                        }}
                      />
                      <FontAwesomeIcon
                        icon={faStar}
                        size="1x"
                        style={{
                          color: "#F7C912",
                          stroke: "#D4D4D4",
                          strokeWidth: "15",
                        }}
                      />
                      <FontAwesomeIcon
                        icon={faStar}
                        size="1x"
                        style={{
                          color: "#F7C912",
                          stroke: "#D4D4D4",
                          strokeWidth: "15",
                        }}
                      />
                    </span>
                    <br />
                    <p
                      style={{
                        color: "#737373",
                        textAlign: "left",
                      }}
                    >
                      Beleaguereddog0, 12.27.2020
                    </p>
                    <p
                      style={{
                        color: "#3D3D3D",
                        marginBottom: "2px",
                        textAlign: "left",
                      }}
                    >
                      This App Has No Right To Be As Fun As It Is
                    </p>
                    <p
                      style={{
                        color: "#3D3D3D",
                        textAlign: "left",
                      }}
                      className="reviews-p"
                    >
                      I initially downloaded this, thinking of it as more of a
                      kid’s app, but I actually love it so much?? I wish more
                      people knew about it; so many people of all ages could
                      benefit from its lessons. Also the art is really calming
                      and good. Thank you developers, you’re helping those
                      hurting improve their lives one step at a time :)
                    </p>
                  </div>
                </Carousel.Caption>
              </Carousel.Item>

              <Carousel.Item
                interval={3000}
                style={{
                  width: "100%",
                  minHeight: "200px",
                }}
              >
                <img
                  className="d-block w-100 slider-bg-img"
                  src={slideBG}
                  style={{ minHeight: "300px" }}
                  alt="First slide"
                />
                <Carousel.Caption>
                  <div
                    style={{
                      paddingTop: "0px",
                    }}
                  >
                    <span
                      style={{
                        marginLeft: "0px",
                        float: "left",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faStar}
                        size="1x"
                        style={{
                          color: "#F7C912",
                          stroke: "#D4D4D4",
                          strokeWidth: "15",
                        }}
                      />
                      <FontAwesomeIcon
                        icon={faStar}
                        size="1x"
                        style={{
                          color: "#F7C912",
                          stroke: "#D4D4D4",
                          strokeWidth: "15",
                        }}
                      />
                      <FontAwesomeIcon
                        icon={faStar}
                        size="1x"
                        style={{
                          color: "#F7C912",
                          stroke: "#D4D4D4",
                          strokeWidth: "15",
                        }}
                      />
                      <FontAwesomeIcon
                        icon={faStar}
                        size="1x"
                        style={{
                          color: "#F7C912",
                          stroke: "#D4D4D4",
                          strokeWidth: "15",
                        }}
                      />
                      <FontAwesomeIcon
                        icon={faStar}
                        size="1x"
                        style={{
                          color: "#F7C912",
                          stroke: "#D4D4D4",
                          strokeWidth: "15",
                        }}
                      />
                    </span>
                    <br />
                    <p
                      style={{
                        color: "#737373",
                        textAlign: "left",
                      }}
                    >
                      Lala London, 15.11.2019
                    </p>
                    <p
                      style={{
                        color: "#3D3D3D",
                        marginBottom: "2px",
                        textAlign: "left",
                      }}
                    >
                      A game that helped my mind!
                    </p>
                    <p
                      style={{
                        color: "#3D3D3D",
                        textAlign: "left",
                      }}
                      className="reviews-p"
                    >
                      I was curious how a game could help my mental health and
                      build my resilience. I played eQuoo and loved learning
                      about emotional bids and how reacting towards an emotional
                      bid improves your connections at work and with friends and
                      family. The app has definitely changed how I interact with
                      people and has given me new skills to apply to everyday
                      life. Thank you eQuoo!
                    </p>
                  </div>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item
                interval={3000}
                style={{
                  width: "100%",
                  minHeight: "200px",
                }}
              >
                <img
                  className="d-block w-100 slider-bg-img"
                  src={slideBG}
                  style={{ minHeight: "300px" }}
                  alt="First slide"
                />
                <Carousel.Caption>
                  <div
                    style={{
                      paddingTop: "0px",
                    }}
                  >
                    <span
                      style={{
                        marginLeft: "0px",
                        float: "left",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faStar}
                        size="1x"
                        style={{
                          color: "#F7C912",
                          stroke: "#D4D4D4",
                          strokeWidth: "15",
                        }}
                      />
                      <FontAwesomeIcon
                        icon={faStar}
                        size="1x"
                        style={{
                          color: "#F7C912",
                          stroke: "#D4D4D4",
                          strokeWidth: "15",
                        }}
                      />
                      <FontAwesomeIcon
                        icon={faStar}
                        size="1x"
                        style={{
                          color: "#F7C912",
                          stroke: "#D4D4D4",
                          strokeWidth: "15",
                        }}
                      />
                      <FontAwesomeIcon
                        icon={faStar}
                        size="1x"
                        style={{
                          color: "#F7C912",
                          stroke: "#D4D4D4",
                          strokeWidth: "15",
                        }}
                      />
                      <FontAwesomeIcon
                        icon={faStar}
                        size="1x"
                        style={{
                          color: "#F7C912",
                          stroke: "#D4D4D4",
                          strokeWidth: "15",
                        }}
                      />
                    </span>
                    <br />
                    <p
                      style={{
                        color: "#737373",
                        textAlign: "left",
                      }}
                    >
                      Becky Loucks-Morris, 14.06.2019
                    </p>
                    <p
                      style={{
                        color: "#3D3D3D",
                        marginBottom: "2px",
                        textAlign: "left",
                      }}
                    >
                      {" "}
                    </p>
                    <p
                      style={{
                        color: "#3D3D3D",
                      }}
                      className="reviews-p"
                    >
                      This is an amazing app! Its insightful, fun, eye opening,
                      thoughtful and creative. I think it could be used as a
                      tool to help kids and adults understand themselves and
                      others better. Im very glad I downloaded it. I also
                      strongly suggest to anyone whos on the fence and
                      considering downloading it as well to absolutely go ahead
                      and get it. Thanks from myself to the wonderful people who
                      created this awesome app! Please continue doing more like
                      this. You rock!
                    </p>
                  </div>
                </Carousel.Caption>
              </Carousel.Item>

              <Carousel.Item
                interval={3000}
                style={{
                  width: "100%",
                }}
              >
                <img
                  className="d-block w-100 slider-bg-img"
                  src={slideBG}
                  style={{ minHeight: "300px" }}
                  alt="First slide"
                />
                <Carousel.Caption>
                  <div
                    style={{
                      paddingTop: "0px",
                    }}
                  >
                    <span
                      style={{
                        marginLeft: "0px",
                        float: "left",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faStar}
                        size="1x"
                        style={{
                          color: "#F7C912",
                          stroke: "#D4D4D4",
                          strokeWidth: "15",
                        }}
                      />
                      <FontAwesomeIcon
                        icon={faStar}
                        size="1x"
                        style={{
                          color: "#F7C912",
                          stroke: "#D4D4D4",
                          strokeWidth: "15",
                        }}
                      />
                      <FontAwesomeIcon
                        icon={faStar}
                        size="1x"
                        style={{
                          color: "#F7C912",
                          stroke: "#D4D4D4",
                          strokeWidth: "15",
                        }}
                      />
                      <FontAwesomeIcon
                        icon={faStar}
                        size="1x"
                        style={{
                          color: "#F7C912",
                          stroke: "#D4D4D4",
                          strokeWidth: "15",
                        }}
                      />
                      <FontAwesomeIcon
                        icon={faStar}
                        size="1x"
                        style={{
                          color: "#F7C912",
                          stroke: "#D4D4D4",
                          strokeWidth: "15",
                        }}
                      />
                    </span>
                    <br />
                    <p
                      style={{
                        color: "#737373",
                        textAlign: "left",
                      }}
                    >
                      Mystique1224, 24.10.2020
                    </p>
                    <p
                      style={{
                        color: "#3D3D3D",
                        marginBottom: "2px",
                        textAlign: "left",
                      }}
                    >
                      Wow! Talk about a shot of dopamine!{" "}
                    </p>
                    <p
                      style={{
                        color: "#3D3D3D",
                      }}
                      className="reviews-p"
                    >
                      This was done so well! I lose interest in things quickly,
                      and am easily distracted, (I’m sure you see the ADHD in
                      this, thus I lack dopamine) and this was so well done, in
                      the way that it flowed so smoothly, kept my interest, and
                      actually was intriguing and interesting enough for me to
                      want to keep at it.
                    </p>
                  </div>
                </Carousel.Caption>
              </Carousel.Item>
            </Carousel>
          </div>
        </Col>
      </Row>
    </section>

    <section
      style={{ position: "relative", marginTop: "4%" }}
      className="section-container"
    >
      <Row>
        <Col lg="12">
          <p style={{ textAlign: "center", fontWeight: "700" }}>
            eQuoo, #1 app for emotional health
          </p>
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <p style={{ textAlign: "center" }}>
            Build your people's resilience and <br /> personal growth while
            reducing anxiety <br /> and depression, all through eQuoo.
          </p>
        </Col>
      </Row>
      <Row>
        <Col lg="12" className="d-flex justify-content-center">
          <Link to="/contact-us">
            <Button className="gradient-button">BOOK A DEMO</Button>
          </Link>
          
        </Col>
      </Row>
    </section>
    <section>
      <Row style={{ marginTop: "auto", marginBottom: "0px", paddingTop: "7%", paddingLeft: "0px" }}>
        <Col lg="12">
          <img
            src={handHeldPhone}
            alt="Hand held phone"
            style={{ width: "100%", height: "auto", marginBottom: "0px" }}
          />
          <div></div>
        </Col>
      </Row>
    </section>

    <section className="blog-section">
                <Row style = {{padding: "0px", backgroundColor: "#E4F5FC"}}>
                <Col lg={{ span:8, offset: 2 }}>
                <p style={{fontWeight: "700", textAlign: "center", marginTop: "4%", fontSize: "18px", color: "black"}}>Blog</p>
                  <BlogList />
                </Col>
                </Row>

            </section>
    <section>
        <Row >
              <Col lg={{ span:8, offset: 2 }} >
                  <p style={{textAlign: "center", fontWeight: "700", paddingTop: "5%", marginBottom: "5px"}}>Let's talk! 
                  </p>
                  <p style={{textAlign: "center", marginBottom: "5px"}}>Ready to see eQuoo in action? 
                  </p>
                  <p style={{textAlign: "center"}}>Request a demo with our team and discover how eQuoo can build the resilience and 
                  emotional health of your organisation.
                  </p>
              </Col>
          </Row>
          <Row style={{marginBottom: "3%"}}>
          <Col lg="12" className="d-flex justify-content-center">
          <Link to="contact-us">
            <Button className="gradient-button">BOOK A DEMO</Button>
          </Link>
        </Col>
          </Row>
    </section>
  </Layout>
)

export default IndexPage
